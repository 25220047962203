.skills {
  width: 100%;
  display: grid;
  grid-template-rows: 100px;
  color: #1a171a;
  place-items: center;
}

.skilss_header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #d9ae6a;
  font-size: 1.5rem;
}

.skills-bottomline {
  border: 2px solid #1a171a;
  width: 5rem;
}
.skills-main-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  place-items: center;
}

.skills-left-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
}

#skills-header {
  width: 70%;
  text-align: start;
  font-size: 1.8rem;
}

.skills-right-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.skill-text {
  width: 70%;
}

.per-skills {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  justify-content: center;
  align-items: center;
  width: 70%;
}

.skill-container {
  width: 100%;
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: flex-start;
  border-radius: 10px;
  padding: 1rem;
  background-color: #d9ae6a;
}

.skills-container .icons {
  color: #1a171a;
  cursor: pointer;
}

.skill-container div:nth-child(2) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
}

.skill-container div:nth-child(2) h4 {
  font-size: 1.25rem;
}

.skill-container div:nth-child(2) p {
  font-size: 1rem;
}

.skills-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.coding-desk {
  width: 80%;
  height: 80%;
  object-fit: cover;
  border-radius: 2rem;
  margin-top: 8rem;
}

@media screen and (max-width: 768px), screen and (max-height: 500px) {
  .skilss_header {
    font-size: 1.2rem;
  }

  .skills {
    margin-top: 10vh;
  }

  #skills-container-header {
    font-size: 3rem;
  }

  .skills-main-container {
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .skills-image-container {
    width: 100%;
    height: 100%;
  }

  .coding-desk {
    height: 100%;
    object-fit: cover;
    margin-top: 0rem;
  }

  .skills-left-container {
    font-size: 0.9rem;
    height: 70%;
    gap: 1rem;
  }
  .skills-right-container {
    padding-bottom: 1rem;
  }
  .skill-text {
    width: 80%;
  }
  #skills-header {
    width: 80%;
    font-size: 1.2rem;
  }
  .per-skills {
    width: 80%;
    gap: 0.5rem;
  }
  .skill-container {
    gap: 0.5rem;
    z-index: -1;
  }

  .skill-container div:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0rem;
  }

  .skill-container div:nth-child(2) h4 {
    font-size: 1rem;
  }

  .skill-container div:nth-child(2) p {
    font-size: 0.8rem;
  }
  .skills-container .icons:hover {
    transform: translateY(0);
  }
}

@media screen and (max-height: 500px) {
  .skills {
    margin-top: 10vh;
    height: 100%;
  }
}
