.header {
  width: 100%;
  height: 10vh;
  background-color: #1a171a;
  color: #d9ae6a;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 1px 1px black;
  z-index: 9999;
}

.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10vw;
  height: 10vh;
}
.img-container a {
  width: 70%;
  height: 80%;
  text-decoration: none;
  cursor: pointer;
}
.logo_diyar {
  margin: 0 auto;
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.navbar li {
  transition: all 0.5s;
  list-style-type: none;
}
.navbar li:hover {
  transform: translateY(-10px);
}
.navbar li a {
  color: #d9ae6a;
  text-decoration: none;
}

.navbar li a:hover {
  cursor: pointer;
  color: #ffffff;
}

.mobil-menu {
  display: none;
}

@media screen and (max-width: 768px) {
  .mobil-menu {
    display: block;
    cursor: pointer;
  }

  .header {
    max-width: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0rem 1.5rem;
  }

  .img-container a {
    width: 100%;
    height: 70%;
    text-decoration: none;
    cursor: pointer;
  }

  .logo_diyar {
    width: 100%;
    height: 100%;
  }
  .navbar {
    position: fixed;
    height: 100vh;
    width: 4rem;
    background-color: #1a171a;
    top: 0;
    right: -100%;
    display: flex;
    justify-content: flex-start;
    padding-top: 4rem;
    align-items: center;
    flex-direction: column;
    margin-top: 10vh;
    transition: 0.5s ease;
  }

  .navbar-active {
    right: 0%;
    transition: 0.5s ease;
  }
}
