/* background-color: #1a171a;  Siyah*/
/* color: #d9ae6a;  Altın*/

.project {
  width: 100%;
}

.project-h1-container {
  padding-top: 6rem;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #d9ae6a;
  padding-bottom: 6rem;
}

.project-bottomline {
  border: 2px solid #1a171a;
  width: 5rem;
}

.project-card-container {
  padding-top: 2rem;
  width: 90%;
  margin: 0 auto;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.card {
  position: relative;
  margin: 0 auto;
  border-radius: 1rem;
  display: flex;
  color: #1a171a;
  width: 90%;
  height: 100%;
  gap: 2rem;
  border: 7px solid black;

  border-radius: 5px;
  cursor: pointer;
}

.image-container-project {
  width: 100%;
  height: 100%;
}
.img-section-container {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-content {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  height: 0;
  width: 100%;
}

.card:hover .text-content {
  opacity: 1;
  background-color: #d9ae6a;
  width: 100%;
  height: 100%;
  transition: 0.7s ease-in;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  opacity: 1;
}

.links_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 1.5rem;
  gap: 1rem;
}
.links_router a {
  text-decoration: none;
  color: white;
}

.links_router a:hover {
  transition: 0.3s ease;
  color: #1a171a;
}

.content-project-container {
  width: 100%;
  height: 100%;
}

.btn-container {
  padding: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app_nameContent {
  padding-left: 1rem;
  display: flex;
  width: 100%;
  height: 80%;
  flex-direction: column;
  gap: 2rem;
  line-height: 2;
}

.tech-stack {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 100%;
}

.tech-stack span {
  background-color: #1a171a;
  color: white;
  border-radius: 10px;
  padding: 0.3rem 1rem;
  border: 2px solid black;
}

.loadmore-btn {
  width: 10rem;
  height: 3rem;
  background-color: #d9ae6a;
  border-radius: 0.4rem;
  border: #d9ae6a;
  color: #1a171a;
  cursor: pointer;
  transition: all 0.5s;
}

.loadmore-btn:hover {
  transform: translateY(-10px);
  background-color: #1a171a;
  color: white;
}

@media screen and (max-width: 768px) {

  .project-card-container {
    display: grid;
    grid-template-columns: 1fr;
  }
  .project-h1-container {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .links_container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 1rem;
    gap: 0.5rem;
  }

  .app_nameContent {
    font-size: 0.8rem;
    padding-left: 1rem;
    display: flex;
    width: 100%;
    height: 75%;
    flex-direction: column;
    gap: 0.5rem;
    line-height: 1.1;
  }

  .tech-stack {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    width: 100%;
  }

  .tech-stack span {
    background-color: #1a171a;
    color: white;
    font-size: 0.7rem;
    border-radius: 10px;
    padding: 0.3rem 1rem;
    border: 1px solid black;
  }
}
