.main-container {
  max-width: 1450px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  place-items: center;
  width: 100%;
  height: 90vh;
}

.main-container h1 {
  /* color: #d9ae6a; */
  font-size: 5rem;
}
#home-message {
  font-size: 6rem;
  /* color: #1a171a; */
  color: #d9ae6a;
}

.main-left-container {
  width: 80%;
  padding: 4rem 0 0 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
}

.social-media-list {
  display: flex;
  gap: 3rem;
}
.social-media-list li {
  list-style-type: none;
  color: black;
}

.social-media-list li a {
  text-decoration: none;
}

.social-media-list li a {
  color: #1a171a;
}

.typical-container {
  display: flex;
  font-size: 3rem;
  color: #1a171a;
}

.Resume-btn a {
  border: 5px solid red;
  width: 10rem;
  height: 3rem;
  color: #1a171a;
  text-decoration: none;
}
.Resume-btn {
  width: 10rem;
  height: 3rem;
  background-color: #d9ae6a;
  border-radius: 0.4rem;
  border: #d9ae6a;
  color: #1a171a;
  cursor: pointer;
  transition: all 0.5s;
}

.Resume-btn:hover {
  transform: translateY(-10px);
  background-color: #1a171a;
  color: white;
}

.icons {
  color: #1a171a;
  transition: all 0.5s;
}

.icons:hover {
  color: #d9ae6a;
  transform: translateY(-10px);
}

/* Main kısmının sağ tarafı */
.main-right-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-container {
  width: 60%;
  height: 50%;
}

.main_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

/* //Animasyonlar */
@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

.main-left-container {
  animation: slideInLeft 1s;
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

.main-right-container {
  animation: slideInRight 1s;
  /* animation: hidescroll; */
}

@keyframes hide-scroll {
  from,
  to {
    visibility: hidden;
  }
}

/* Mobil görünüm */

@media screen and (max-width: 768px), screen and (max-height: 500px) {
  .main-container h1 {
    font-size: 3rem;
  }
  .typical-container {
    display: flex;
    font-size: 1.7rem;
  }
  #home-message {
    font-size: 3rem;
  }

  .main-container {
    display: flex;
    flex-direction: column;
    max-width: 100vw;
    padding: 3rem 0 0 0.5rem;
    place-items: flex-start;
    gap: 0rem;
  }

  .main-left-container {
    width: 90%;
    padding: 0;
    gap: 2rem;
    padding-left: 2.8rem;
    animation: slideInLeft 1.6s;
  }

  .social-media-list {
    display: flex;
    gap: 2rem;
  }

  .main-right-container {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }
  .image-container {
    width: 100%;
    height: 100%;
    padding: 2rem;
  }

  .icons:hover {
    color: #d9ae6a;
    transform: translateX(20px);
  }
}

@media screen and (max-height: 500px) {
  .main-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 0.5rem 0 0 0.5rem;
    place-items: flex-start;
  }

  .main-left-container {
    gap: 1.5rem;
  }
  .social-media-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 1.75rem;
  }

  .main-right-container {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }
  .image-container {
    width: 50%;
    height: 100%;
  }

  .image-container {
    width: 100%;
    padding-right: 2rem;
    padding-bottom: 2rem;
  }

  .icons:hover {
    color: #d9ae6a;
    transform: translateX(20px);
  }
}
