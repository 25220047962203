.footer-container {
  padding: 2rem 0rem;
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.social-media-links-v2 {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  gap: 2rem;
}

.footer-img-container {
  height: 30%;
  width: 5%;
}

.footer-img-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.footer-icon-container .icons {
  color: #d9ae6a;
}

.footer-icon-container .social-media-list {
  gap: 3rem;
}

.footer-icon-container .icons:hover {
  color: white;
}

@media screen and (max-width: 768px) {
  .footer-img-container {
    height: 35%;
    width: 20%;
  }
  .footer-container {
    width: 100%;
    padding: 4rem 0rem;
  }
  .footer-icon-container .social-media-list {
    gap: 2rem;
  }

  .footer-container p {
    text-align: center;
    width: 100%;
    color: white;
    font-size: 0.8rem;
  }
}
