.whole-container {
  max-width: 1450px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.main {
  margin: 0 auto;
  background-color: #fff;
  margin-top: 10vh;
  height: 90vh;
  width: 100%;
  display: flex;
  justify-content: center;
}

.about-container {
  width: 100%;
  margin: 0 auto;
}

.skills-container {
  width: 100%;
}

.project-blog {
  width: 100%;
}

.footer {
  width: 100%;
  background-color: #1a171a;
}

@media screen and (max-height: 500px) {
  .whole-container {
    max-width: 750px;
  }
}
