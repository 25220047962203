@import url("https://fonts.googleapis.com/css2?family=Kanit:ital@1&family=Mulish:ital@1&family=Pangolin&family=Rubik:wght@500&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Kanit", sans-serif;
  font-family: "Mulish", sans-serif;
  font-family: "Pangolin", cursive;
  font-family: "Rubik", sans-serif;
}


html {
  scroll-behavior: smooth;
  scroll-padding-top: 4rem;
}

body {
  overflow-x: hidden;
}
