@counter-style deneme {
  system: fixed;
  symbols: 🧙‍♂️ 👽 👨‍💻 🦎 🚴‍♂️;
  suffix: " ";
}

.about {
  width: 90%;
  margin: 0 auto;
  margin-top: 10vh;
  height: 90vh;
}

.about-h1 {
  font-size: 1.5rem;
  padding-top: 2rem;
  display: flex;
  color: #d9ae6a;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}

.bottom-line {
  width: 5rem;
  height: 0.3rem;
  background-color: #1a171a;
}
.about-content-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  place-items: center;
  height: calc(100% - 70px);
}
.left-about {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 5rem;
  z-index: -1;
}

.right-about {
  padding-left: 5rem;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: -1;
}

.about-left-img-container {
  width: 90%;
  height: 70%;
}

.about-img {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  object-fit: cover;
}

.right-about-text-container {
  width: 100%;
  height: 70%;
  display: grid;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.about-list {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: start;
  padding-left: 2rem;
  gap: 2rem;
  list-style-type: deneme;
}

.about-list li {
  color: #d9ae6a;
  font-weight: 100;
}

.about-preferences {
  color: #1a171a;
  font-weight: 700;
}

.about-text {
  word-spacing: 0.3rem;
}

@media screen and (max-width: 768px), screen and (max-height: 500px) {
  .about-content-container {
    margin: 0 auto;
    display: flex;
    width: 80%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .about-left-img-container {
    width: 100%;
    height: 80%;
    padding: 1rem;
  }
  .left-about {
    padding: 0;
    z-index: -1;
  }

  .right-about-text-container {
    width: 100%;
    height: 100%;
    font-size: 0.8rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
    padding-right: 1rem;
  }

  .about-list {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: start;
    padding-left: 0rem;
    gap: 0.5rem;
    list-style-type: deneme;
  }

  .about-text {
    font-size: 0.8rem;
    word-spacing: 0.1rem;
  }

  .right-about {
    padding-left: 2rem;
    z-index: -1;
  }
}

@media screen and (max-height: 500px) {
  .about {
    width: 100%;
    height: 100%;
  }
  .about-content-container {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .about-left-img-container {
    width: 70%;
    height: 100%;
  }
  .left-about {
    justify-content: center;
    align-items: center;
    z-index: -1;
  }
  .right-about-text-container {
    gap: 1rem;
  }

  .about-list {
    gap: 0.5rem;
  }
}

.animation {
  width: 400px;
  height: 80px;
  background-color: brown;
  margin: 10px;
}
